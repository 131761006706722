.receipt-viewer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.receipt-viewer-image-area {
  position: relative;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);
  height: calc(85% - 100px);
}

.receipt-viewer-image-button {
  position: absolute;
  width: 15%;
  height: 100%;
  z-index: 1;
  top: 0;
  cursor: pointer;
}

.receipt-viewer-image-button:hover {
  background-color: #00000033;
}

.receipt-viewer-image-button-prev {
  left: 0;
}

.receipt-viewer-image-button-next {
  right: 0;
}

.receipt-viewer-image-button-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.receipt-viewer-image-button-icon-prev {
  background-image: url('../../assets/icons/chevron-left.png');
  left: 20px;
}

.receipt-viewer-image-button-icon-next {
  background-image: url('../../assets/icons/chevron-right.png');
  right: 20px;
}
